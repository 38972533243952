<template>
    <div class="card shadow-sm card-hover" :onclick="cardActive ? 'window.open(\'' + link + '\', \'_blank\');' : null" style="cursor: pointer;">
        <div class="card-header text-center m-2" style="background-color: #495057; min-height: 75px;" >
            <i class="fad fa-3x" :class="iconClass" style="color: white; --fa-secondary-opacity: 0.40"></i>
        </div>
        <div class="card-body border-top highlight">
            <b-overlay :show="!cardActive || false" :opacity="0.4" spinner-type="null">
                <h6 class="card-subtitle mb-2">{{ title }}</h6>

                <div class="d-flex justify-content-between">
                    <p class="card-text">
                        {{ description }}
                    </p>
                </div>
                <br />
                <div class="d-flex justify-content-between align-items-center">
                    <button type="button" class="btn btn-outline-dark btn-block mt-0"><i class="far fa-arrow-up-right-from-square"></i> {{ buttonText }}</button>
                </div>
            </b-overlay>
        </div>
        <div class="card-footer">
            <small class="text-muted">
                <i v-if="footer && footer !== '&nbsp;'" class="fad fa-lock fa-fw mr-1"></i>
                <span v-html="footer"></span>
            </small>
        </div>
    </div>
</template>

<script>
    
    export default {
        name: "PortalModuleSubExternalCard",
        props: ['iconClass', 'title', 'description', 'buttonText', 'footer', 'link', 'cardActive'],
        data(){
          return {
          }  
        },
        computed:{
        },
        created() {
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>